// types
import { makeStyles } from '@mui/styles';
import { ConfigProps } from 'types/template/config';

export const ABSENCE_TYPE: any = {
    ANNUAL_LEAVE: 'ANNUAL LEAVE',
    AM_ANNUAL_LEAVE: 'AM ANNUAL LEAVE',
    PM_ANNUAL_LEAVE: 'PM ANNUAL LEAVE',
    HOSPITALISATION_LEAVE: 'HOSPITALISATION LEAVE',
    CHILD_CARE_LEAVE: 'CHILD CARE LEAVE',
    MEDICAL_LEAVE: 'MEDICAL LEAVE',
    OFF_IN_LIEU: 'OFF IN LIEU',
    BIRTHDAY_LEAVE: 'BIRTHDAY LEAVE',
    URGENT_LEAVE: 'URGENT LEAVE'
};

export const TypeRouter = {
    main: 'main',
    auth: 'auth',
    template: 'template'
};

export const MethodShowAlert = ['post', 'put', 'patch', 'delete'];

export const UserRoles = [
    {
        name: 'Administrator',
        value: 'Administrator'
    },
    {
        name: 'Executive',
        value: 'Executive'
    },
    {
        name: 'Teacher',
        value: 'Teacher'
    },
    {
        name: 'Non-Teaching',
        value: 'Non-Teaching'
    }
];

export const RoleNull: null = null;
export enum Roles {
    Admin = 'HQ Admin',
    HrAdmin = 'HR Admin',
    HrIc = 'HR IC',
    Cluster = 'Cluster Manager',
    Principals = 'Principals',
    User = 'Users'
}

export enum RedirectPath {
    DASHBOARD_PRINCIPAL = '/dashboard/principal',
    DASHBOARD_HQADMIN = '/dashboard/hqadmin',
    DASHBOARD_SIZING = '/dashboard/sizing',
    DASHBOARD_REPORTS = '/dashboard/reports',
    STAFF_DEPLOYMENT = '/staff/cal-staff-ratio',
    STAFF_LIST = '/staff/staff-list',
    RELIEF_LIST = '/staff/relief-list',
    RELIEF_TICKET = '/staff/relief-ticket',
    CLUSTER_MANAGEMENT = '/cluster/cluster-list',
    USER_MANAGEMENT = '/user/user-list',
    RATIO_LIST = '/staff/list-ratio',
    RATIO_LIST_EDIT = '/staff/list-ratio/edit',
    ABSENCE_LIST = '/staff/absence-list',
    QUALIFICATION_LIST = '/staff/qualification-list',
    HRIC_MANAGEMENT = '/staff/hric-management',
    CENTRE_LIST = '/centre/centre-list',
    CLASS_LIST = '/class/class-list',
    TIMESHEET_LIST = '/staff/timesheet-approval',
    STAFF_ATTENDANCE_LIST = '/staff/attendance-list'
}

export enum Order {
    ASC = 'ASC',
    DESC = 'DESC'
}

export enum StatusAttendaceEnum {
    ABSENT = 'absent',
    PRESENT = 'present'
}

export enum StatusLeave {
    WAITING_FOR_APPROVAL = 'WAITING FOR APPROVAL',
    APPROVED = 'APPROVED',
    CANCELLATION_WAITING_FOR_APPROVAL = 'CANCELLATION WAITING FOR APPROVAL'
}

export const DEFAULT_PAGINATION = {
    order: Order.ASC,
    page: 1,
    take: 10,
    sortColumn: '',
    query: ''
};

export const DEFAULT_REQUEST = {
    order: Order.ASC,
    page: 1,
    take: 10,
    sortColumn: '',
    query: ''
};

export const GET_ALL_REQUEST = {
    order: Order.ASC,
    sortColumn: '',
    query: ''
};

export const DEFAULT_CENTER_OPTION = {
    id: 'babilou',
    nameCenter: 'Babilou'
};

export const ALL_CENTER_OPTION = {
    id: 'all',
    nameCenter: 'All'
};

export enum ReliefStatus {
    // DRAFT = 'Draft',
    // PRIN_COMPLETED = 'Principal completed',
    // OPEN = 'Open',
    // APPROVED = 'Approved',
    // COMPLETED = 'Completed',
    // PRIN_REJECT = 'Principal rejected optimization',
    // CM_REJECT = 'CM rejected',
    // PENDING_ASSIGNATION = 'Pending assignation',
    // RELIEF_ASSIGNED = 'Relief assigned',
    // CM_ASSIGNED_RELIEF = 'CM assigned relief',
    // EXPIRED = 'Expired',
    // MISSING_ACTION = 'Missing in action',
    // RESOLVED_LATE = 'Resolved late',
    // RELIEF_ON_HOLD = 'Relief on-hold',
    // PENDING_APPROVAL = 'Pending approval',
    // READY_FOR_ASSIGNMENT = 'Ready for Assignment',
    DRAFT = 'Draft',
    STAFF_ASSIGNED = 'Staff Assigned',
    CM_REJECT = 'CM rejected',
    PENDING_STAFF_ASSIGNATION = 'Pending Staff Assignation',
    PENDING_RELIEF_ASSIGNATION = 'Pending Relief Assignation',
    RELIEF_ASSIGNED = 'Relief Assigned',
    ATTENDED = 'Attended'
}

export enum TIMESHEET_STATUS {
    OPEN = 'Open',
    WAITING_APPROVAL = 'Waiting for approval',
    APPROVED = 'Approved',
    REJECTED = 'Rejected'
}

export enum SEARCH_SCOPE {
    IN_CENTER = 'in_center',
    IN_CLUSTER = 'in_cluster',
    THREE_KM = '3km',
    ALL = 'all'
}

export enum RELIEF_FROM {
    MY_CLUSTER = 'my_cluster',
    OTHER_CLUSTER = 'other_cluster'
}

export enum RELIEF_AGENT {
    DIRECT_STAFF = 'Direct Staffing',
    BABILOU = 'Babilou family',
    SEMIKO = 'Semiko',
    INTELLISEARCH = 'Intellisearch',
    WE_GOT_THIS = 'We got this'
}

export const generateHalfHourTimes = (startHour: any, endHour: any) => {
    const times = [];
    // eslint-disable-next-line no-plusplus
    for (let hour = startHour; hour <= endHour; hour++) {
        times.push(hour);
        if (hour < endHour) {
            times.push(hour + 0.5);
        }
    }
    return times;
};
const startTime = 7;
const endTime = 19;
export const timeOfDay = generateHalfHourTimes(startTime, endTime);

export const ReasonForRequestRelief = [
    { id: 1, text: 'To cover a dry Open Position (Resigned, no replacement yet)' },
    { id: 2, text: 'To cover a dry Open Position (For new enviroiment)' },
    { id: 3, text: 'To cover staff on Medical Leave (>3 days)' },
    { id: 4, text: 'To cover staff on Hospitalisation Leave (>3 days)' },
    { id: 5, text: 'Other reasons, please specify in remarks' }
];

export const AUTH_LOCAL_STORAGE_KEY = {
    ACCESS_TOKEN: 'token',
    REFRESH_TOKEN: 'refreshToken',
    ACC_AZURE: 'acc_azure',
    TYPE_LOGIN: 'type_login',
    FCM_TOKEN: 'fcm_token',
    USER_INFO: 'user_info_kecho',
    MY_PLAN_DEFAULT_CENTRE: 'my_plan_default_centre_id',
    SELECT_CENTER_ID: 'select_center_id'
};

export const TYPES_LOGIN = {
    azure: 'azure',
    normal: 'normal'
};

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const SIZING_CLASS_TYPE = {
    OVERALL: 'overall',
    CLASS_BREAKDOWN: 'class-breakdown'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyBnnBc_oiDhZxZKGjxqH5vgAJk3uMwkixY',
    authDomain: 'kecho-qa.firebaseapp.com',
    projectId: 'kecho-qa',
    storageBucket: 'kecho-qa.appspot.com',
    messagingSenderId: '229732629626',
    appId: '1:229732629626:web:451a4d39dfacb5edfdcfc3',
    measurementId: 'G-NCYVKKN3GN'
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard/default';
export const HOME_PATH = '/home';
export const CENTRE_LIST_PATH = '/centre/centre-list';
export const PROFILE_MY_PLAN = '/user/my-plan';
export const PROFILE_PATH = '/user/profile';
export const SELECT_CENTER_PATH = '/center/select-center';

export const defaultSuccessAlert = (message: string) => ({ open: true, message, variant: 'alert', alert: { color: 'primary' } });
export const defaultErrorAlert = (message: string) => ({ open: true, message, variant: 'alert', alert: { color: 'error' } });

// export const ConfigAzure = {
//     appId: '9745feda-6f70-44c0-8161-2b9e3f7ee305',
//     redirectUrl: window.location.origin,
//     scopes: ['user.read'],
//     authority: 'https://login.microsoftonline.com/9a18f771-1690-4e3e-9845-b4e639ce8a44'
// };

export const ConfigAzure = {
    appId: '394528ae-4596-40e2-8ef5-4e6090ea3d24',
    redirectUrl: `${window.location.origin}/login`,
    scopes: ['user.read'],
    authority: 'https://login.microsoftonline.com/9a18f771-1690-4e3e-9845-b4e639ce8a44'
};

export const ErrRefreshToken = {
    normal: 'Unauthorized',
    azure: 'Validate token azure faild'
};

const config: ConfigProps = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export const REACT_APP_VERSION = 'v3.1.2';
// export const REACT_APP_API_URL = `https://kecho-api-dev.techvify.dev`;
export const REACT_APP_API_URL = `https://kecho-api-uat.techvify.dev/api`;

export const ImageDefault =
    'https://static.vecteezy.com/system/resources/thumbnails/008/442/086/small/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg';

export const formatDate = {
    DATE_DEFAULT: 'YYYY/MM/DD',
    DATE_MONTH: 'MM/DD/YYYY',
    DATE_DAY: 'DD/MM/YYYY'
};

export const formatHour = {
    HOUR_MINUTE: 'HH:MM',
    HOUR_MINUTE_SEC: 'HH:MM:SS'
};

export const ConvertTimeOfDayColums: any = (time: any) => {
    return time.map((item: any) => {
        return {
            id: item.title,
            label: item.title,
            value: Number(item.title?.replace('H', '')),
            minWidth: 170
        };
    });
};

export const convertTime = (time: any) => {
    if (!time) {
        return '';
    }
    const timeInt = Number(time);
    const isInt = Number.isInteger(timeInt);
    if (time < 10) {
        if (isInt) {
            return `0${time}${isInt ? ':00' : ':30'}`;
        }
        return `0${Math.floor(time)}${isInt ? ':00' : ':30'}`;
    }

    if (isInt) {
        return `${time}${isInt ? ':00' : ':30'}`;
    }
    return `${Math.floor(time)}${isInt ? ':00' : ':30'}`;
};

export const convertTimeOfDay = (time: any) => {
    const isInt = Number.isInteger(time);
    if (time < 10) {
        if (isInt) {
            return `${time}${isInt ? 'H' : ':30H'}`;
        }
        return `${Math.floor(time)}${isInt ? 'H' : ':30H'}`;
    }
    return `${Math.floor(time)}${isInt ? 'H' : ':30H'}`;
};

export const useStyles = makeStyles(() => ({
    selectMenu: {
        maxHeight: '300px'
    }
}));

export default config;
